.dotList {
    display: inline-block;
    margin: 0;
    padding: 10px 0;
    list-style-type: none;
}


.dotList :hover{
    transform: scale(2);
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
}


.dotList .dot {
    display: inline-block;
    width: 4px;
    max-width: 4px;
    height: 4px;
    max-height: 4px;
    margin-right: 3px;
    border-radius: 50%;
    background-color: #d2d2d2;
    transition: all 0.25s;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.dotList .dot {
    width: 8px;
    max-width: 8px;
    height: 8px;
    max-height: 8px;
    margin-right: 12px;
}

.dotList .active {
    background-color: #054F57;
}

.dotList .dot:last-child {
    margin-right : 0;
}
