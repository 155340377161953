.o-Section3{
    background-color:#ffffff; 
    height: auto;
    padding: 0 34px;
    display: flex;
    flex-direction: column;
}

/* *Section 3 image */

.o-Section-Cont .o-carousel-title{
    font-family: 'Quicksand';
    font-size: 4vw;
    color: #00C0B4;
    margin: 0px;
    align-self: start;
}

@media (max-device-width: 1023px) {

    .o-Section2_carousel{
        height: auto;
    }
    
    .o-Section3{
        height: auto;
    }

    .o-Section-Cont .o-carousel-title{
        margin: 5vw 0px !important;
    }
    .o-Section2-Cont_serv{
        width: 100%;
        margin: 0;
    }
}

@media  (min-device-width: 1640px) {
    #Section3{
        padding: 0px 4% ; 
    }
}

@media  (min-device-width: 1920px) {

    #Section3{
        padding: 0px 6%;
    }
}