@media (min-device-width: 0px) and (max-device-width:768px){
    .our-differnces {       
        padding: 0px;
        justify-content: flex-end;
        flex-direction: column-reverse;
        height: auto;
        min-height: auto;
    }

    .od-content{
        width: 100%;
        padding: 15% 4% 10% 20%;
    }
    
    .od-img{
        height: 1px;
        width: 100%;
    }

    .od-img img{
        top: -10vw;
        width: 40%;
    }

    .od-content-title{
        text-align: start;
        font-family: 'Quicksand';
        font-weight: bolder;
        color: #AEB530;
    }

    .our-differnces::before{
        content: '';
        background-position: bottom left 107%;
    }
}

@media (min-device-width: 425px) and (max-device-width:768px){
    .od-img img{
        top: -10vw;
        width: 37%;
    }
}

@media (min-device-width: 600px) and (max-device-width:768px){
    .od-img img{
        top: -10vw;
        width: 36%;
    }
}