.header-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    width: 80px;
    right: 0.5%;
    z-index: 6;   
}

.header-menu img{
    width: 55%;
}
@media  (min-device-width: 0px) and (max-device-width: 1007px) {
    #header{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}


@media  (min-device-width: 0px) and (max-device-width: 553px) {
    #header{
        height: 70px;
        padding: 0px 7%; 
        min-width: 320px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .WaduaColor{
        margin-right: 0px;
        width: 35%;
        height: auto;
    }

    .header-menu img{
        width: 40%;
    }
}



