.our-differnces{
    background:  #008392;
    padding-right: 13%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    overflow: inherit;   
    position: relative;
    
}

.our-differnces::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #AEB530;
    background: url(/public/resources/Fondos/textureServices.svg);
    background-repeat: no-repeat;
    background-position: bottom left 79%;
    background-size: 52vw;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}
.od-content{
    width: 50%;
    position: relative;
    z-index: 6;
}

.od-content-title{
    text-align: start;
    font-family: 'Quicksand';
    font-weight: bolder;
    color: #AEB530;
}

.od-content-p{
    color: #fff;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
}

.od-img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    position: relative;
    overflow-x: clip;
}

.od-img img{
    position: absolute;
    left: -10%;
    top: -20%;
    width: 55%;
    height: auto;
}


/* * Querys */

@media (min-device-width: 769px){
    .od-img img{
        left: -13%;
        width:80%;
    }
}
@media (min-device-width: 900px){
    .od-img img{
        left: -13%;
        width:70%;
    }
}
@media (min-device-width: 1000px){
    .od-img img{
        left: -13%;
        width: 67%;
    }
}
@media (min-device-width: 1024px){
    .od-img img{
        left: -13%;
        width: 65%;
    }
}
@media (min-device-width: 1224px){
    .od-img img{
        width: 60%;
    }
}
@media (min-device-width: 1624px){
    .od-img img{
        width: 55%;
    }
}
@media (min-device-width: 2000px){
    .od-img img{
        width: 52%;
    }
}
@media (min-device-width: 2150px){
    .od-img img{
        width: 50%;
    }
}
@media (min-device-width: 2300px){
    .od-img img{
        width: 47%;
    }
}