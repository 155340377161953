.o-be-part{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/public/resources/Fondos/bannerEndPage.jpg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

.o-be-part .o-img{
    height: 60%;
    width: auto;
}

.o-be-part .o-img img{
    height: 100%;
}


@media  (min-device-width: 1920px) {
    .o-be-part .o-img img{
        width: 60%;
    }
}