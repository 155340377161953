.about-us-C-desc{
    display: flex;
    padding: 0 34px;
    background-color: #008392;
    position: relative;
    min-height: 100%;
}

@media  (min-device-width: 1640px) {
    .about-us-C-desc{
        padding: 0px 4% ; 
    }
}

@media  (min-device-width: 1920px) {
    .about-us-C-desc{
        padding: 0px 6%;
    }
}

.about-us-C-desc .abusc-img{
    width: 50%;
    height: 100%;
    position: relative;    
    padding: 0px;
    margin: 0px;
}

.about-us-C-desc::before{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 65%;
    height: 200%;
    top: -20%x;
    left: -9%;
    border-radius: 50%;
    background: url(/public/resources/Fotos/us.png) #aeb530;
    background-position: center;
    background-position-y: 6vw;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.about-us-C-desc .abusc-content{
    width: 50%;
    height: auto;
    padding: 1% 4% 0% 11%;
}

.about-us-C-desc .abusc-content .abusc-content-btns{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    bottom: 0vw;
    height: 5vw;
    gap: 3%;
}

.about-us-C-desc .abusc-content .abusc-content-btns button{
    width: 50%;
    height: 47px;
    width: 50%;
    max-height: 184px;
    min-width: 130px;
    max-width: 188px;

}

.about-us-C-desc .abusc-content .abusc-content-title{
    color: #AEB530;
}

.about-us-C-desc .abusc-content p{
    color: #f4f4f4;
}

@media  (min-device-width: 2100px) {
    .about-us-C-desc::before{
        background-position-y: 3vw;
    }
}






