.o-card{
    width: 40%;
    height: 30vw;
    min-width: 50px;
    margin: 4vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    border-radius: 0 5vw 0 0;
    -webkit-border-radius: 0 5vw 0 0;
    -moz-border-radius: 0 5vw 0 0;
    -ms-border-radius: 0 5vw 0 0;
    -o-border-radius: 0 5vw 0 0;
}

.o-card .o-card-cont{
    width: 100%;
    height: 80%;
    display: flex;
}

.o-card .o-card-cont .o-card-cont-left{
    width: 40%;
    height: 100%;
    display: flex;
}

.o-card .o-card-cont .o-card-cont-left .o-card-cont-left-indicator{
    height: 4vw;
    width: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    border-radius: 0 0 2vw 0;
    background-color: #AEB530;
    -webkit-border-radius: 0 0 2vw 0;
    -moz-border-radius: 0 0 2vw 0;
    -ms-border-radius: 0 0 2vw 0;
    -o-border-radius: 0 0 2vw 0;
}

.o-card .o-card-cont .o-card-cont-left img{
    width: 100%;
    z-index: 0;
}

.o-card .o-card-cont .o-card-cont-left .o-card-cont-left-indicator p{
    font-family: "Quicksand";
    color: white;
    font-size: 2.2vw;
    font-weight: bold;
}

.o-card .o-card-cont .o-card-text{
    width: 60%;
    height: 100%;
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #008291;
}

.o-card .o-card-cont .o-card-text h3{
    font-family: "Quicksand";
    color: white;
    font-size: 1.7vw;
    font-weight: 200;
}


.o-card .o-card-tittle{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.o-card .o-card-tittle h3{
    font-family: "Quicksand";
    color: white;
    font-size: 1.7vw;
    font-weight: bold;
}

label:hover .o-flip-card{
    transform: rotateY(20deg);
}

input{
    display: none;
}

:checked + .o-flip-card{
    transform: rotateY(180deg);
}

label:hover :checked + .o-flip-card{
    transform: rotateY(175deg);
}

@media (max-device-width: 1023px) {
    .o-card{
    width: 100%;
    height: 100%;
    margin: 0;
    }

    .o-carousel-tab-services{
    width: 100vw;
    height: 60vw;
    }

    .o-card .o-card-cont .o-card-text h3 {
        font-size: 3.5vw;
    }

    .o-card .o-card-tittle h3 {
        font-size: 3.5vw;
    }
}
