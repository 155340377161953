.menu{
    display: flex;  
    width: 100%;
}

.menu a,.menu span{
    position: relative;
    color: #979797;
    font-weight: bold;
    text-decoration: none;
    transition:  0.5s ease-out;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.menu a:hover
,.menu span:hover{
    color: #008392;
}

.menu a::before,.menu span::before{
    content: '';    
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:  #008392;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.menu a:hover::before, .menu span:hover::before {    
    transform: scaleX(1);
  }

.menu ul{
    padding: 0px 5%;
    display: flex;
    width: 100%;
    list-style: none; 
    position: relatives;   
}

.menu ul li{
    position: relative;
    z-index: 3;   
}

.menu .isHeader {      
    font-size: 23px;
    gap: 5%;
    
}

.menu .noHeader {   
    gap: 9%;
    font-size: 1.5vw;      
}

/* * Menu-des styles */

.menu .internal-nav{
    position: absolute;
    background-color: #ffffff;
    z-index: -6;
    width: auto;
    height: auto;
    top: -10px;
    left: -10px;
    display: none;
    flex-direction: column;
    padding: 50% 25% 10% 25%;
    align-items: flex-start;
    font-size: 1.5vw;
    
}

.menu .internal-nav li{
    z-index: 1;
}

.menu .internal-nav li a{
    color: #AEB530;
}

.menu .internal-nav li a:hover{
    color: #AEB530;
}

.menu .internal-nav li a:hover::before{
    background-color:  #AEB530;
}

.is-active{
    font-weight: bolder;
}

.menu .dropdown:hover .internal-nav{
    display: flex;
        -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
                animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    
}

.prueba {
    display: none;
}

.menu .dropdown:focus
.prueba {
    display: flex;
}

.about-us-C-desc .abusc-img img{
    width: 100%;
    height: auto;
}




@media  (min-width: 1640px) {
    .menu .isHeader {      
        font-size: 25px;
        
    }    
    .menu .noHeader {   
        gap: 10.5%;
        font-size: 1.2vw;      
    }
}

@media  (max-width: 1023px) {
    .menu .noHeader{
        font-size: 15.5px;
    }
}





/*
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
*/
@-webkit-keyframes swing-in-top-fwd {
    0% {
        transform: rotateX(-100deg);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
}
@keyframes swing-in-top-fwd {
    0% {
        transform: rotateX(-100deg);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
}

