.our-values{
    width: 100%;
    height: auto;
    padding: 4% 25% 4% 13%;
    position: relative;
    overflow: hidden;
}
.our-values::before{
    content: '';
    background-color: transparent;
    width: 500vw;
    height: 200vw;
    position: absolute;
    right: -35%;
    bottom: -35%;
    border-radius: 50%;
    box-shadow: -20px 20px 0px 200vw #48c0b4;
    z-index: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.our-values::after{
    content: '';
    background-color: #FFF;
    width: 100%;
    height: 4%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0% 0% 100% 100%;
    z-index: 0;
    -webkit-border-radius: 0% 0% 100% 100%;
    -moz-border-radius: 0% 0% 100% 100%;
    -ms-border-radius: 0% 0% 100% 100%;
    -o-border-radius: 0% 0% 100% 100%;
}

.ov-title{
    width: 100%;
    text-align: start;
    font-family: 'Quicksand';
    color: #AEB530;
    font-weight: bolder;
}

.ov-content{
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    row-gap: 2.3vw;
    position: relative;
    z-index: 3;
}

.ov-card{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;
    background-color: #FFF;
    overflow: hidden;
    border-radius: 10px;
    transition: 3ms;
    min-width: 240px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.ov-card:hover{
    box-shadow: 3px 3px 0px 0px #008392;
}
.ov-card-title{
    color: white;
    background-color: #008392;
    margin-top: 0px;
    height: auto;
    font-size: 2vw;
    text-transform: uppercase;
    width: 80%;
    border-radius: 0% 0px 10px 10px;
    padding: 5% 3%;
    -webkit-border-radius: 0% 0px 10px 10px;
    -moz-border-radius: 0% 0px 10px 10px;
    -ms-border-radius: 0% 0px 10px 10px;
    -o-border-radius: 0% 0px 10px 10px;
}

.ov-card-p{
    padding: 0% 7% 7% 7%;
}


.ov-desc{
    padding: 0px 2% 0px 0%;
    margin-bottom: 32px;
    text-align: justify;
}