.bannerP{
    background-color: #054F57;
    overflow: hidden;
    position: relative;
    min-width: 320px;
}

.bannerP::after{
    content: '';
    border-radius: 40%;
    position: absolute;
    width: 300vw;
    height: 60vw;
    background-color: transparent;
    right: -5vw;
    bottom: -3vw;
    box-shadow: 20px 20px 0px 100vw #48c0b4;
    -webkit-border-radius: 40%;
    -moz-border-radius: 40%;
    -ms-border-radius: 40%;
    -o-border-radius: 40%;
}
.bannerP::before{
    content: '';
    border-radius: 80%;
    position: absolute;
    width: 108vw;
    height: 28vw;
    background-color: #AEB530;
    left: -49vw;
    top: -20vw;
    /*box-shadow: 20px 20px 0px 100vw #48c0b4;
    */-webkit-border-radius: 40%;
    -moz-border-radius: 80%;
    -ms-border-radius: 80%;
    -o-border-radius: 80%;
    -webkit-border-radius: 80%;
}

.bannerP-img{
    width: 21vw;
    height: auto;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(13%) hue-rotate(275deg) brightness(106%) contrast(106%);
    -webkit-filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(13%) hue-rotate(275deg) brightness(106%) contrast(106%);
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(13%) hue-rotate(275deg) brightness(106%) contrast(106%);
}

.corner{
    border-radius: 80%;
    position: absolute;
    width: 108vw;
    height: 28vw;
    background-color: #919220 ;
    left: -72vw;
    top: -22vw;
    /*box-shadow: 20px 20px 0px 100vw #48c0b4;
    */-webkit-border-radius: 40%;
    -moz-border-radius: 80%;
    -ms-border-radius: 80%;
    -o-border-radius: 80%;
    -webkit-border-radius: 80%;
}
@media (min-device-width: 0px) and (max-device-width:1006px){
    .bannerP{
        height: 200px;
        min-height: 200px;
    }

    .bannerP::after{
        height: 100vw;
    }

    .bannerP-img{
        width: 35vw;
        height: auto;
    }
    .bannerP::before{
        width: 108vw;
        height: 30vw;
    }
    .corner{
        left: -72vw;
        top: -20vw;
    }
}
@media (min-device-width: 435px) and (max-device-width:1006px){
    .bannerP{
        height: 230px;
        min-height: 230px;
    }

    /* .bannerP::after{
        height: 90vw;
    } */

    .bannerP-img{
        width: 34vw;
    }
    .bannerP::before{
        width: 107vw;
        height: 30vw;
    }
}
@media (min-device-width: 528px) and (max-device-width:1006px){
    .bannerP{
        height: 250px;
        min-height: 250px;
    }
    .bannerP-img{
        width: 32.5vw;
    }
    .bannerP::before{
        width: 107vw;
        height: 30vw;
    }
}
@media (min-device-width: 600px) and (max-device-width:1006px){
    .bannerP{
        height: 270px;
        min-height: 270px;
    }
    .bannerP-img{
        width: 29vw;
    }
    .bannerP::before{
        width: 106vw;
        height: 28vw;
    }
    .corner{
        left: -72vw;
        top: -22vw;
    }
    .bannerP::after {
        height: 80vw;
    }
}
@media (min-device-width: 769px) and (max-device-width:1006px){
    .bannerP{
        height: 270px;
        min-height: 270px;
    }
    .bannerP-img{
        width: 24vw;
    }
    .bannerP::before{
        width: 106vw;
        height: 28vw;
    }
    .corner{
        left: -72vw;
        top: -22vw;
    }

    .bannerP::after {
        height: 70vw;
    }
}

@media (min-device-width: 790px) and (max-device-width:1006px){
    .bannerP{
        height: 290px;
        min-height: 290px;
    }
    .bannerP-img{
        width: 23vw;
    }
}


@media (min-device-width: 1007px) and (max-device-width:1240px){
    .bannerP-img{
        width: 22vw;
    }
}
@media (min-device-width:1500px){
    .bannerP::before{
        width: 106vw;
        height: 27vw;
    }
    .bannerP-img{
        width: 20vw;
    }
    .corner{
        left: -72vw;
        top: -23vw;
    }
}
@media (min-device-width:1740px){
    .bannerP-img{
        width: 19vw;
    }
}
@media (min-device-width:2000px){
    .bannerP::before{
        content: '';
        border-radius: 80%;
        position: absolute;
        width: 105.8vw;
        height: 26.3vw;
        background-color: #AEB530;
        left: -49vw;
        top: -20vw;
        -webkit-border-radius: 80%;
        -moz-border-radius: 80%;
        -ms-border-radius: 80%;
        -o-border-radius: 80%;
}
    .bannerP-img{
        width: 18.5vw;
    }
    
}
@media (min-device-width:2100px){
    .bannerP-img{
        width: 18vw;
    }
    
}
@media (min-device-width:2400px) and (max-device-width:2553px){
    .bannerP-img{
        width: 17vw;
    }
    
}