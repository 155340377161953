@-webkit-keyframes slide-left {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
  }
}

.isMovil{
    display: flex;
    position: fixed;
    right: 0px;
    top: 0px;
    background:  #FFF;
    height: 100vh;
    min-height: 100vh;
    width: 60vw;
    -webkit-animation: slide-left 0.5s ease-in forwards;
	  animation: slide-left 0.5s ease-in forwards;
    min-width: 224px;
}


.isMovil ul{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    list-style: none;
}

.isMovil .isHeader {      
    font-size: 6vw;
    gap: 4%;
    
}

.isMovil .drpdwnLi a{
  color: #AEB530;
}

.close-btn{
    position: absolute;
    width: 8%;
    top: 7%;
    right: 8%;
    
}

@media  (min-width: 426px) {
  .isMovil .isHeader {
    font-size: 5vw;
  }
}
@media  (min-width: 560px) {
  .isMovil .isHeader {
    font-size: 4.6vw;
  }
}
@media  (min-width: 643px) {
  .isMovil .isHeader {
    font-size: 4.3vw;
  }
}
@media  (min-width: 720px) {
  .isMovil .isHeader {
    font-size: 3.7vw;
  }
}
@media  (min-width: 800px) {
  .isMovil .isHeader {
    font-size: 3.5vw;
  }
}


  