@font-face {
  font-family: 'Phenomena';
  src: url('./fonts/Phenomena-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./fonts/Quicksand-VariableFont_wght.ttf');
  font-weight: normal;
}

@-ms-viewport{
  zoom: 1.0;
  width: device-width;
}

@-o-viewport{
  zoom: 1.0;
  width: device-width;
}

@viewport{
  zoom: 1.0;
  width: device-width;
}

*{
  box-sizing: border-box;
}

body {
  margin: 0px !important;
  padding: 0px !important; 
  width: 100vw; 
  /* height: 100vh; */
  /* min-height: 100%; */
  /* overflow-x: hidden; */
  background-color: #F4F4F4;
  font-family: 'Phenomena',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Phenomena',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.disable{
  display: none !important;
}

a, a:hover, a:active, a:visited, a:focus {
  text-decoration:none;
}



/* *P General Styles */

p{
  font-size: 15px;
  text-align: justify;
  font-family: 'Quicksand';
  color: #054F57;;
}

@media  (min-device-width: 0px) and (max-device-width: 1023px) {
  p{  
    font-size: 14px;
  }
}

@media  (min-device-width: 1024px) {

p{
    font-size: 16px;
}

}

@media  (min-device-width: 1440px) {

p{
    font-size: 18px;
}
}

@media  (min-device-width: 1920px) {
p{
    font-size: 20px ;
}
}
@media  (min-device-width: 2220px) {
p{
    font-size: 22px ;
}
}



/* * Sections General Styles */
  .Section{  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    min-height: 400px;
    min-width: 990px;
    overflow: hidden;
  }

  @media  (min-device-width: 0px) and (max-device-width: 1023px) {
    .Section{  
      min-width: 320px;
    }
  }

  @media  (min-device-width: 1024px){
    .Section{ 
      height: 400px;
    }
  } 
  @media  (min-device-width: 1440px){
    .Section{ 
      height: 450px;
    }
  } 
  @media  (min-device-width: 1920px){
    .Section{ 
      height: 500px;
    }
  } 
  @media  (min-device-width: 2560px){
    .Section{ 
      height: 600px;
    }
  } 
/* End Sections General Styles*/

/* *Caurousel general Styles */

/* .carousel__inner-slide { /* Space between carousel cards
  width: calc(100% - 16px);
  margin-left: 8px;  
} */

/* @media (min-width: 1272px) {
  .carousel__inner-slide {
    width: calc(100% - 34px);
    margin-left: 17px;
  }
} */


/* *Title general Styles */

.title-h2{
  font-size: 2.2vw;
  font-family: 'Quicksand';
  /* margin: 0px 0px 25px 0px; */
}

.general-title-h2{
  font-weight: bolder;
  font-size: 250%;
  margin-bottom: 10px;
}

@media (max-device-width: 357px){
  .general-title-h2{
    font-size: 170%;
  }
}
@media (max-device-width: 457px) and (min-device-width: 358px){
  .general-title-h2{
    font-size: 200%;
  }
}

@media (min-device-width: 1441px){
  .general-title-h2{
    font-size: 300%;
  }
}
@media (min-device-width: 1800px){
  .general-title-h2{
    font-size: 350%;
  }
}