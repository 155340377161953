.o-service {
  display: flex;
  padding: 0 0 1% 0;
  background-color: white;
  height: 500px;
  min-height: auto;
}

.o-service .o-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 3vw 10%;
  background-color: #ffffff;
  background-image: url("/public/resources/Fondos/Services/Services-Recurso-01.svg");
  background-repeat: no-repeat;
  background-size:cover;
}

.o-service .o-content .o-content-title {
  color: #AEB530;
  font-family: "Quicksand";
  font-size: 4vw;
  margin: 0;
}

@media (max-device-width: 1023px) {
  .o-service {
    height: 400px;
  }

  .o-service .o-content {
    background-image: url("/public/resources/Fondos/Services/ServicesM-Recurso-01.svg");
    border-radius: 0;
    justify-content: flex-start;
    align-items: center;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

  .o-service .o-content .o-content-title {
    font-size: 8vw;
    text-align: left;
  }
  
}

@media (min-device-width: 1440px) {
  .o-service {
    height: 500px;
  }
}