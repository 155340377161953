/* *General Styles */

.buttonBorder{
    background-color: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 19px;
    border-top-right-radius: 19px;
    border: 2px solid #008392;
    padding: 0px;
    transform: perspective(1px) translateZ(0)
}

.buttonBorder:hover{
    cursor: pointer;
}

.buttonBorder::before,
.buttonBorder::after
{
    content: "";
    background: #008392;
    position: absolute;
    width: 0;
    height: 0;
    transform: translate(-31%,-27%);
    transition: width .5s,height .5s;
    border-radius: 50%;
    z-index: -1;
    pointer-events: none;
    top:0px;
    left: 0px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.buttonBorder:hover::after {
    width: 700px;
    height: 700px; 
} 

.borderAnimationBtn{
    width: 100%;
    height: 100%;
    position: absolute;    
    overflow: hidden;
    padding: 0px;
    margin: 0px;
}
.borderAnimationBtn::before{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 65%;
    height: 206%;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: -52px 2vw 0 0 #aeb530;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

/***************************************/

.buttonBorder .txtBtn{
    text-decoration: none;
    color: #008392;
    font-family: 'Phenomena';
    font-weight: bold;
    font-size: 155%;
    transition: all 400ms;
}

.buttonBorder:hover .txtBtn
{
    color: #FFF;
}

.bck-lightgray{
    background-color: #F4F4F4;
}

/* *Icon Pancho */

.inconP-Btn{
    background-color: #F4F4F4;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    left: -50px;
    bottom: -15px;
    border-radius: 50%;
    border: 2px solid #aeb530;
    z-index: 5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.inconP-Btn img{
    width: 77%;
    height: 100%;
}

.iconPanchoActive{
    display: block;
}

.iconPanchoNone{
    display: none;
}
/* Querys */


/* * Button Gray */

.greenBtn{border: 2px solid #FFF;}
.greenBtn:hover{
    cursor: pointer;
}

.greenBtn::before,
.greenBtn::after
{
    background: #BABD3E;
}

.greenBtn:hover .borderAnimationBtn::before{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 65%;
    height: 206%;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: -52px 2vw 0 0 #ABAE30;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}


@media (min-device-width: 0px) and (max-device-width: 900px){
    .borderAnimationBtn::before{
        width: 78%;
        bottom: -4%;
        left: 1%;
    }
}
@media (min-device-width: 1024px){
    .inconP-Btn{
        width: 75px;
        height: 75px;
    }
}
@media (min-device-width: 1440px){
    .inconP-Btn{
        width: 80px;
        height: 80px;
    }
}

@media (min-device-width: 1640px){
    .inconP-Btn{
        width: 83px;
        height: 83px;
    }
}

@media (min-device-width: 1920px){
    .inconP-Btn{
        width: 85px;
        height: 85px;
    }
}
@media (min-device-width: 2562px){
    .inconP-Btn{
        width: 95px;
        height: 95px;
    }
}

@media (max-width: 1180px) and (min-width: 770px) {
    .buttonBorder .txtBtn{
        font-size: 130%;
    }
    
}

@media (min-width: 1640px) {
    .buttonBorder .txtBtn{
        font-size: 170%;
    }

}

