@media  (min-device-width: 0px) and (max-device-width: 768px) {
    #Section2{
        position: relative;
        padding: 0 0%;
        display: flex;
        flex-direction: column-reverse;
        height: 550px;
    }
    
    #Section2::after{
        content: "";
        background-color: #F4F4F4;
        box-shadow: 0px -4px 40px 30px #008392;
    }

    .S2-content .S2-content-info .S2-Btn{
        min-height: 50px;
    }

    .S2-image{
        height: 40%;
        width: 100%;
        position: relative;
        overflow: hidden;
        background: url('/public/resources/Fotos/pandaImg.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .S2-image::after{
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 100% 0% 100% 0% / 0% 58% 42% 100%  ;
        box-shadow: 0px -4px 0px 104px #48c1b5;
        -webkit-border-radius: 100% 0% 100% 0% / 0% 58% 42% 100%  ;
        -moz-border-radius: 100% 0% 100% 0% / 0% 58% 42% 100%  ;
        -ms-border-radius: 100% 0% 100% 0% / 0% 58% 42% 100%  ;
        -o-border-radius: 100% 0% 100% 0% / 0% 58% 42% 100%  ;
}

    .S2-content{
        position: relative;
        display: flex;
        height: 60%;
        width: 100%;
        overflow: hidden;
    }

    .S2-content::after{
        content: '';
        position: absolute;
        width: 150%;
        height: 150%;
        border-radius: 50%;
        right: -100%;
        top:-40%;
        background-color:  #007685;
        z-index: 1;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
}
    
    .S2-content .S2-content-info{
        padding: 0% 5% 0% 5%;
        z-index: 2;
    }

    .S2-content .S2-content-info h2{
        color: #AEB530;
        margin: 7px 0px 25px 0px;
        z-index: 2;
    }
}
@media  (min-device-width: 426px) and (max-device-width: 768px){
    #Section2{
        height: 590px;
    }
    
    #Section2::after{
        content: "";
        position: absolute;
        background-color: #F4F4F4;
        top: -4%;
    }

    .S2-image{
        height: 60%;
    }

    .S2-image::after{
        box-shadow: 0px -4px 0px 124px #48c1b5;
    }

    .S2-content{
        height: 50%;
    }

    .S2-content::after{
        content: '';
        width: 50%;
        height: 140%;
        right: -25%;
        top: -40%;
        ;
    }
    
    .S2-content .S2-content-info{
        padding: 0% 5% 0% 5%;
    }

    
}
@media  (min-device-width: 769px) and (max-device-width: 899px){
    #Section2{
        height: 500px;
    }
}
@media  (min-device-width: 900px) and (max-device-width: 1090px){
    #Section2{
        height: 450px;
    }
}