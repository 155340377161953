.footer .content .section1 .iconPancho{
    background-color: #008392;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 40%;
    border-radius: 50%;
    flex: none;
    padding: 6%;
    min-width: 54px;
    min-height: 54px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.footer .content .section1 .iconPancho img{
    width: 100%;
    height: 100%;
}

.footer .content .section1 .Our-SM{
    display: flex;
    align-items: center; 
    flex-direction: column;
    width: 50%;
}
.footer .content .section1 .Our-SM h4{
    color: #008392;
    font-family: 'Quicksand';
    text-align: center;
    text-transform: uppercase;
    font-size: 3.7vw;
    font-weight: 600;
    /* margin: 0px;     */
    white-space: nowrap;
}

.footer .content .section1 .Our-SM .SM-icons{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media  (min-device-width: 0px) and (max-device-width: 768px) {
    .footer{
        min-width: 320px;
        height: auto;
        overflow: hidden;
    }

    /* *  ____________________________________________Content styles */
    .footer .content{
        flex-direction: column;
        padding: 24% 20% 0 20%;
    }

    .footer .content::after{
        content: "";
        left: 0;
        top: -8%;
        width: 116%;
        height: 15%;
        border-radius: 50%;
        z-index: 1;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
}

    /* * _________Section 1 styles */

    .footer .content .section1{
        justify-content: center;
        align-items: center;
    }
   

    /* * _________Section 2 styles */

    .footer .content .section2 span:first-child{
        font-size: 3.2vw;
        margin-top: 13%;
    }
    .footer .content .section2 h3{
        font-size: 6.3vw;
    }
    .footer .content .section2 form {
        width: 90%;
    }

    .footer .content .section2 .S2-logo{
        width: 70%;
        height: auto;
        margin: 15% 0% 10% 13%;
        -webkit-filter: brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(1090%) hue-rotate(155deg) brightness(92%) contrast(100%);
                filter: brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(1090%) hue-rotate(155deg) brightness(92%) contrast(100%);         
    }
    .footer .content .section2 .S2-logo img{
        width: 100%;
        height: 100%;
    }

     /* * _________Section 3 styles */

     .footer .content .section3{
        position: relative;
    }

     .footer .content .section3 .line{
        background-color: #054F57;
        right: 50%;
        top: -35%;
        height: 80%;
        transform: rotate(90deg);
    }

    .footer .content .section3 img{
        width: 80%;
        height: auto;
        margin-bottom: 8%;
    }

    /* * _________Bottom 2 styles */

    .footer .bottom{
        display: flex;
        align-items: center;
        padding: 3% 20%;
    }
    .footer .wadua{
        height: 30%;
    }
}

@media  (min-device-width: 426px) and (max-device-width: 768px) {
    

    .footer .content{
        flex-direction: column;
        padding: 24% 25% 0 25%;
    }
}

@media  (min-device-width: 769px) and (max-device-width: 1007px){
    
    .footer{
        min-width: 768px;
    }

    .footer .content{
        padding: 0 10px;
    }

    .footer .content .section1{
        flex: 1 1;
    }

    /* * Section 1 styles */ 

    .footer .content .section1 .S1-logo-desc{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1 1;
    }

    .footer .content .section1 .S1-menu{
        display: none;
    }

    .footer .content .section3{
        position: relative;
    }


    .footer .content .section3 img{
        width: 80%;
        height: auto;
        margin-bottom: 8%;
    }

    .footer .content .section3 .line{
        left: 0px;
        bottom: 29%;
        height: 41%;
    }
}