.o-Section4{
    background-color:#ffffff; 
    height: auto;
    display: flex;
    flex-direction: column;
}

/* *Section 3 content */

.S3-content p{
    text-align: justify;
    margin: 0px 0px 25px 0px;
    color: #054F57;
}

.S3-content .S3-Btn{
    height: 13%;
    width: 50%;
    max-height: 184px;
    min-width: 154.4px;
    max-width: 188px;
    position: relative;
}

/* *Section 3 image */

.o-Section-Cont .o-carousel-title{
    font-family: 'Quicksand';
    font-size: 4vw;
    color: #00C0B4;
    margin: 0px;
    align-self: start;
}

@media  (max-device-width: 1023px) {
    .o-Section4{
        height: auto;
        min-height: auto;
    }
}

@media  (min-device-width: 1640px) {
    #Section3{
        padding: 0px 4% ; 
    }
}

@media  (min-device-width: 1920px) {

    #Section3{
        padding: 0px 6%;
    }
}