@media  (min-device-width: 0px) and (max-device-width: 1023px){

    .Section2ABUC-desc{
        background-color: #F4F4F4;
        position: relative;
        overflow: hidden;
        padding: 0 0 0 0px;
        height: auto;
        min-height: auto;
    }
    .Section2ABUC-desc .upper-slice{
        height: 11%;
        background-color: #FFF;
    } 
    
    .Section2ABUC-desc .upper-slice::before{
        display: none;
    }

    .Section2ABUC-content{
        width: 100%;
        height: 100%;
        padding: 0% 5%;
        margin: 10% 0px 7% 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Section2ABUC-content .Section2ABUC-content-title{
        text-align: left;
        font-size: 6.7vw;
        color: #AEB530;
    }
    
    
}

