.o-Section4 .o-Section-Cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-bottom: 2vw;
}

.o-Section4 .o-Section-Cont .o-installs-tittle{
width: 100%;
height: 20%;
padding: 0 7vw;
display: flex;
flex-wrap: wrap;
align-content: center;
background-color: #008291;
}

.o-Section-Cont .o-installs-tittle .o-carousel-title{
    font-family: 'Quicksand';
    font-size: 3vw;
    color: #ffffff;
    text-align: left;
    margin: 2vw 0;
}

.o-Section4 .o-Section-Cont .o-carousel-tab{
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0 20px 0 20px;
    padding: 0%;
    width: 100vw;
    height: 40vw;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 0 20px 0 20px;
    -moz-border-radius: 0 20px 0 20px;
    -ms-border-radius: 0 20px 0 20px;
    -o-border-radius: 0 20px 0 20px;
}

.o-Section4 .o-Section-Cont .o-carousel-tab .o-carousel-cont{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-install-tittle {
    background-color: #AEB530;
    width: 40%;
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-install-tittle p{
    font-family: 'Quicksand';
    font-size: 2vw;
    font-weight: 100;
    color: #ffffff;
    margin: 0;
}

.o-Section-Cont .o-img-installs {
    position: absolute;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 0px 0px #eee;
    border-radius: 0;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

@media (max-device-width: 1023px) {

    .o-Section4 .o-Section-Cont{
        height: auto;
        width: 100%;
    }

    .o-Section-Cont .o-installs-tittle .o-carousel-title{
        font-size: 6vw;
    }

    .o-Section4 .o-Section-Cont .o-carousel-tab{
        width: 100vw;
        height: 60vw;
    }
    
}

@media  (min-device-width: 0px) and (max-device-width: 768px) { 
    
    .carousel-image-container{
        width: 100%;
        padding: 0px;
        height: 38vh;
        min-height: 195px;    
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px;  
        display: flex;
        justify-content: center;
        align-items: center; 
        padding: 8vw 20vw; 
    }

    .carousel-image-container img{
        width: 100%;
        height: 100%;  
    }

    .news-style{
        background-color: transparent;
        width: 100%;
        padding: 0px;
        height: auto;
        min-height: auto;   
    }
    
    .news-style .title-news{   
        margin: 5% 0;
    }
    
    .news-style .title-news .news-title{
        font-size: 6.3vw; 
        line-height: 137%;
    }
    
    .news-style img {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 50px;   
        box-shadow: 0px 0px 19px -12px #979797;
    }
}

@media  (min-device-width: 584px){
    .carousel-image-container{
        height: 48vh;
        padding: 5vw 20vw;
    }

    .news-style{
        height: auto;
        min-height: auto;   
    }
}

@media  (min-device-width: 769px) and (max-device-width: 1007px) {

    .carousel-image-container{
        height: 15vw;
        padding: 3%;
    }
    .news-style {
        width: 45%;
    }

    .news-style{
        height: auto;
        min-height: auto;   
    }

    .news-style .title-news .news-title {
    font-size: 2.3vw;
    line-height: 19px;
    }
}

@media  (min-device-width: 1008px) {

    .carousel-image-container{
        /* width: 11.5vw; */
        height: 12vw;
        padding: 6%;
        min-width: 123px;
    }
    
    .news-style{
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 24%;
        height: auto;
        position: relative;
        padding: 2%; 
    }
    
}

@media (min-width: 1466px){
    .news-style .title-news .news-title {
        line-height: 18px;
        font-size: 1.2vw;
    }
}

@media (min-width: 1700px){
    .news-style .title-news .news-title {
        line-height: 20px;
        font-size: 1.1vw;
      }
}

@media (min-width: 1920px){
    .news-style .title-news .news-title {
        line-height: 21px;
        font-size: 1vw;
    }
    .news-style{
        height: 98%;
    }
}

@media (min-width: 2160px){
    .news-style .title-news .news-title {
        line-height: 25px;
      }
}

@media (min-width: 2560px){
    .news-style .title-news .news-title {
        line-height: 32px;
    }
    .news-style{
        height: 100%;
    }
}

