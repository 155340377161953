.category-teamMembers{
    height: 70%;
    width: 14vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.category-teamMembers-img{
    position: relative;
    display: flex;
    background-color: #0e7182;
    border-radius: 50%;
    border: 0px;
    width: 100%;
    height: 50%;
    border: 4px solid #FFF;
    box-shadow:  0px 0vw 6px 1px #eee;
    overflow: hidden;
    /*min-width: 112px;
    min-height: 99px;
    */justify-content: center;
    align-items: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.category-teamMembers-img .img-team{
    position: relative;
    padding: 22%;
    width: 60%;
    height: 60%;
    z-index: 6;
}

@media (min-device-width:1008px){
    .category-teamMembers-img{
        width: 11vw;
        height: 11vw;      
    }
}



.category-teamMembers-img::before{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 185%;
    height: 180%;
    bottom: -29%;
    right: 64%;
    border-radius: 50%;
    background-color: #128d98;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.Predictiva::after{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 206%;
    height: 180%;
    bottom: -120%;
    left: -13%;
    border-radius: 50%;
    background-color: #044f56;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.UX::after{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 206%;
    height: 180%;
    bottom: -120%;
    left: -13%;
    border-radius: 50%;
    background-color: #7C267D;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.BI::after{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 206%;
    height: 180%;
    bottom: -120%;
    left: -13%;
    border-radius: 50%;
    background-color: #47b8b0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.Consultoría::after{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 206%;
    height: 180%;
    bottom: -120%;
    left: -13%;
    border-radius: 50%;
    background-color: #aeb530;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.category-teamMembers-title,
.title-team{
    font-family: 'Quicksand';
    color: #054F57;
    font-weight: 300;
}

.disable{
    display: none;
}

.carousel-close{
    position: absolute;
    top: 40px;
    left: 10%;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 6;
}

.carousel-close img{
    -webkit-filter: drop-shadow(0px 0px 7px #464646);
            filter: drop-shadow(0px 0px 7px #464646);
    rotate: 180;
    transform: rotate(180deg)
}
.carousel-close:hover img{
    -webkit-filter: brightness(0) saturate(100%) invert(23%) sepia(32%) saturate(7048%) hue-rotate(171deg) brightness(102%) contrast(103%) drop-shadow(0px 0px 0px #46464600);
            filter: brightness(0) saturate(100%) invert(23%) sepia(32%) saturate(7048%) hue-rotate(171deg) brightness(102%) contrast(103%) drop-shadow(0px 0px 0px #46464600);
}

.carousel-team{
    width: 100%;
    margin-top: 15px;
    height: 60%;
    margin: 12px auto;
    padding: 0px 6%;
}
