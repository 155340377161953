@media  (min-device-width: 0px) and (max-device-width: 768px){
    .category-teamMembers-img{
        margin-top: 20px;     
    }
}

@media (min-device-width:0px) and (max-device-width:531px){
    .category-teamMembers-img{
        margin-top: 20px;
        width: 40vw;
        height: 40vw;     
    }
}
@media (min-device-width:532px) and (max-device-width:768px){
    .category-teamMembers-img{
        width: 30vw;
        height: 30vw;     
    }
}
@media (min-device-width:769px) and (max-device-width:1007px){
    .category-teamMembers{
        width: 20vw;
    }
    .category-teamMembers-img{
        width: 17vw;
        height: 17vw;     
    }
}



