
.o-Section-Cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 90%;
    margin: 5vw 0px;
}

.o-Section-Cont .o-img-photo{
    position: absolute;
    height: 90%;
    width: 100%;
    box-shadow: 0px 0px 0px 0px #eee;
    border-radius: 0;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.o-carousel-tab{
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0 20px 0 20px;
    padding: 0%;
    width: 27vw;
    height: 35vw;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 0 20px 0 20px;
    -moz-border-radius: 0 20px 0 20px;
    -ms-border-radius: 0 20px 0 20px;
    -o-border-radius: 0 20px 0 20px;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 90%;
    width: 100%;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin {
    background-color: #AEB530;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px !important;
    width: 35px !important;
    margin: 10px;
    border-radius: 50px;
    overflow: hidden;
    transform: perspective(1px) translateZ(0);
    z-index: 1;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin .o-link-lkin{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin .o-link-lkin img{
    width: 20px;
    height: 20px;
    box-shadow: 0px 0px 0px 0px #eee;
    border-radius: 0;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-name{
    height: 20%;
    width: 100%;
    align-self: flex-end;
    background-color: rgba(174,181,48,1);
    border-radius: 0 100px 0 0;
    z-index: 1;
    -webkit-border-radius: 0 100px 0 0;
    -moz-border-radius: 0 100px 0 0;
    -ms-border-radius: 0 100px 0 0;
    -o-border-radius: 0 100px 0 0;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-name .o-name-decoration{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255,255,255);
    border-radius: 0 30px 0 100px;
    -webkit-border-radius: 0 30px 0 100px;
    -moz-border-radius: 0 30px 0 100px;
    -ms-border-radius: 0 30px 0 100px;
    -o-border-radius: 0 30px 0 100px;
} 

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-name .o-name-decoration .o-name{
    margin: 0;
    font-size: 2.9vw;
    font-weight: bold;
    color: #008392;
}

.o-Section-Cont .o-carousel-tab .o-carousel-post{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
    z-index: 1;
}

.o-Section-Cont .o-carousel-tab .o-carousel-post .o-post-tittle{
    font-family: 'Quicksand';
    font-size: 1.4vw;
    font-weight: 100;
    color: #008392;
    margin: 0;
}

@media (max-device-width: 1023px) {
    .o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin {
        height: 20px !important;
        width: 20px !important;
        margin: 10px;
    }
    
    .o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin .o-link-lkin{
        width: 20px;
        height: 20px;
    }
    
    .o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin .o-link-lkin img{
        width: 10px;
        height: 10px;
    }
}

@media  (min-device-width: 0px) and (max-device-width: 768px) { 
    
    .carousel-image-container{
        width: 100%;
        padding: 0px;
        height: 38vh;
        min-height: 195px;    
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px;  
        display: flex;
        justify-content: center;
        align-items: center; 
        padding: 8vw 20vw; 
    }

    .carousel-image-container img{
        width: 100%;
        height: 100%;  
    }

    .news-style{
        background-color: transparent;
        width: 100%;
        padding: 0px;
        height: auto;
        min-height: auto;   
    }
    
    .news-style .title-news{   
        margin: 5% 0;
    }
    
    .news-style .title-news .news-title{
        font-size: 6.3vw; 
        line-height: 137%;
    }
    
    .news-style img {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 50px;   
        box-shadow: 0px 0px 19px -12px #979797;
    }
}

@media  (min-device-width: 584px){
    .carousel-image-container{
        height: 48vh;
        padding: 5vw 20vw;
    }

    .news-style{
        height: auto;
        min-height: auto;   
    }
}

@media  (min-device-width: 769px) and (max-device-width: 1007px) {

    .carousel-image-container{
        height: 15vw;
        padding: 3%;
    }
    .news-style {
        width: 45%;
    }

    .news-style{
        height: auto;
        min-height: auto;   
    }

    .news-style .title-news .news-title {
    font-size: 2.3vw;
    line-height: 19px;
    }
}

@media  (min-device-width: 1008px) {

    .carousel-image-container{
        /* width: 11.5vw; */
        height: 12vw;
        padding: 6%;
        min-width: 123px;
    }
    
    .news-style{
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 24%;
        height: auto;
        position: relative;
        padding: 2%; 
    }
    
}

@media (min-width: 1466px){
    .news-style .title-news .news-title {
        line-height: 18px;
        font-size: 1.2vw;
    }
}

@media (min-width: 1700px){
    .news-style .title-news .news-title {
        line-height: 20px;
        font-size: 1.1vw;
      }
}

@media (min-width: 1920px){
    .news-style .title-news .news-title {
        line-height: 21px;
        font-size: 1vw;
    }
    .news-style{
        height: 98%;
    }
}

@media (min-width: 2160px){
    .news-style .title-news .news-title {
        line-height: 25px;
      }
}

@media (min-width: 2560px){
    .news-style .title-news .news-title {
        line-height: 32px;
    }
    .news-style{
        height: 100%;
    }
}

