/* ! NOTE: Querys order asc */

/* * _____________________________________________General styles */
.footer{
    background-color: #054F57;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: 440px;
    min-width: 990px;
}

/* Querys General */

@media  (min-device-width: 1441px) {
    .footer{height: 490px;}
}

@media  (min-device-width: 1920px) {
    .footer{height: 520px;}
}

/* *  ____________________________________________Content styles */

.footer .content{
    background-color: #E6E6E6;
    display: flex;
    width: 100%;
    height: 73%;
    padding: 0 34px;
}

.footer .content::after{
    content: "";
    background-color: #F4F4F4;
    position: absolute;
    right: 0px;
    width: 100%;
    height: 4%;
    border-radius: 0% 0% 0% 100%;
    z-index: 1;
    -webkit-border-radius: 0% 0% 0% 100%;
    -moz-border-radius: 0% 0% 0% 100%;
    -ms-border-radius: 0% 0% 0% 100%;
    -o-border-radius: 0% 0% 0% 100%;
}

.footer .content .content-separation{
    flex: 1;
    height: 100%;
}

.footer .content div{
    flex: 1;
    height: 100%;
}

/* Query content */

@media  (min-device-width: 1640px) {
    .footer .content{
        padding: 0 4%;
    }
}

@media  (min-device-width: 1920px) {
    .footer .content{
        padding: 0 6%;
    }
}

/* * _________Section 1 styles */

    .footer .content .section1{
        display: flex;
        flex-direction: column;
        flex: 2;
    }

    .footer .content .section1 .S1-logo-desc{
        display: flex;
        align-items: center;
        height: 75%;
        margin-top: 15%;
    }
    .footer .content .section1 .S1-logo-desc p{
        color: #054F57;
        text-align: justify;
        font-family: 'Quicksand';
        /* font-size: 15px; */
        margin-left: 11%;
        margin-right: 8%;
    }
    .footer .content .section1 .S1-logo-desc img{
        width: auto;
        height: auto;
        margin-left: 2%;
    }
    .footer .content .section1 .S1-menu{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }
    /* Querys Section1 */

    @media (max-width: 1120px) and (min-width: 1024px) {

        /* .footer .content .section1 .S1-logo-desc p{
            font-size: 13px;
        } */

        .footer .content .section1 .S1-logo-desc img{
            width: 43%;
            height: 43%;
        }
    }

    @media  (min-width: 1640px) {

        .footer .content .section1 .S1-logo-desc{
            margin-top: 10%;
        }
        /* .footer .content .section1 .S1-logo-desc p{
            font-size: 17px;
        } */

        .footer .content .section1 .S1-logo-desc img{
            width: 60%;
            height: 60%;
        }
    }

    @media  (min-width: 1921px) {

        .footer .content .section1 .S1-logo-desc{
            margin-top: 8%;
        }

        .footer .content .section1 .S1-logo-desc img{
            width: 80%;
            height: 80%;
        }

        /* .footer .content .section1 .S1-logo-desc p{
            font-size: 19px ;
        } */
    }
/* End Section 1 styles*/

/* *_________Section2 Styles */

    .footer .content .section2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 2%;
    }
    .footer .content .section2 span{
        color: #008392;
        font-family: 'Quicksand';
        margin-left: 10%;
    }
    .footer .content .section2 span:first-child{
        font-weight: bold;
        font-size: 14px;
        margin-top: 23%;
    }
    .footer .content .section2 h3{
        color: #AEB530;
        font-family: 'Quicksand';
        text-align: initial;
        font-weight: bold;
        font-size: 26px;
        margin-top: 1%;
        margin-bottom: 7px;
        margin-left: 10%;
    }
    .footer .content .section2 form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        margin-top: 0px;
        margin-left: 10%;
    }
    .footer .content .section2 form input{
        display: flex;
        color: #008392;
        font-family: 'Quicksand';
        font-size: 12px;
        height: 30px;
        width: 100%;
        border: none;
        border-radius: 20px;
        outline: none;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
}
    .footer .content .section2 form input::-webkit-input-placeholder{
        color: #008392;
        font-family: 'Quicksand';
        font-size: 13px;
    }
    .footer .content .section2 form input::-moz-placeholder{
        color: #008392;
        font-family: 'Quicksand';
        font-size: 13px;
    }
    .footer .content .section2 form input:-ms-input-placeholder{
        color: #008392;
        font-family: 'Quicksand';
        font-size: 13px;
    }
    .footer .content .section2 form input::-ms-input-placeholder{
        color: #008392;
        font-family: 'Quicksand';
        font-size: 13px;
    }
    .footer .content .section2 form input::placeholder{
        color: #008392;
        font-family: 'Quicksand';
        font-size: 13px;
    }
    .footer .content .section2 form button{
        background-color: #AEB530;
        position: relative;
        right: 35px;
        height: 35px;
        width: 40px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
}
    .footer .content .section2 .Our-SM{
        display: flex;
        align-items: flex-start; 
        width: 50%;
        margin-top: 3%;
        margin-left: 10%; 
    }
    .footer .content .section2 .Our-SM h4{
        color: #008392;
        font-family: 'Quicksand';
        text-align: initial;
        font-size: 13px;
        margin: 0px;
        margin-right: 9%;
    }

    .footer .content .section2 .Our-SM .SM-icons{
        display: flex;
        align-items: flex-start;
    }

    /* Querys Section2 */

    @media (max-width: 1120px) and (min-width: 1024px) {
        .footer .content .section2 form {
            margin-bottom: 6%;
        }
    }

    @media  (min-width: 1640px) {
        .footer .content .section2{
            padding-top: 0%;
        }
        .footer .content .section2 span:first-child{
            margin-top: 21%;
        }
        .footer .content .section2 form input {
            color: #008392;
            font-size: 14px;
            height: 37px;
        }
        .footer .content .section2 form input::-webkit-input-placeholder{
            font-size: 15px;
        }
        .footer .content .section2 form input::-moz-placeholder{
            font-size: 15px;
        }
        .footer .content .section2 form input:-ms-input-placeholder{
            font-size: 15px;
        }
        .footer .content .section2 form input::-ms-input-placeholder{
            font-size: 15px;
        }
        .footer .content .section2 form input::placeholder{
            font-size: 15px;
        }
        .footer .content .section2 form button{
            height: 40px;
            width: 45px;    
        }
        .footer .content .section2 .Our-SM h4{
            font-size: 15px;
        }
    }

    @media  (min-width: 1921px) {
        .footer .content .section2 span:first-child{
            margin-top: 19%;
            font-size: 17px;
        }
        .footer .content .section2 h3{
            font-size: 27px;
        }
        .footer .content .section2 .Our-SM  h4{
            font-size: 17px;
        }
    }
/*End Section2 Styles*/

/* *_________Section3 Styles */

.footer .content .section3{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .content .section3 img{
    margin-top: 15%;
    z-index: 3;
}

.footer .content .section3 .line{
    background-color: #054F57;
    position: absolute;
    right: 28%;
    bottom: 45%;
    width: 1px;
    height: 30%;
}

/* Querys Section3 */

@media (max-width: 1120px) and (min-width: 1000px) {
    .footer .content .section3 img{
        width:80%;
    }
    
}

@media  (min-width: 1921px) {
    .footer .content .section3 img{
        width:62%;
        max-width: 300px;
        max-height: 200px;
    }
}

/* * ________________________________________________________Botom styles */

.footer .bottom{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 27%;
}
.footer .bottom .bottom-text{
    color: #FFF;
    font-size: 21px;
    z-index: 3;
}

.footer .bottom .iconPancho{
    background-color: #008392;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    right: 2%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    z-index: 3;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@-webkit-keyframes rotate-vert-center {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

@keyframes rotate-vert-center {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

.footer .bottom .iconPancho:hover{
    -webkit-animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
            animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
.footer .wadua{
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 415px;
    z-index: 2;
}

/* Query bottom*/

@media (min-width: 1640px){
    .footer .bottom{
        padding: 0px 7%;
    }

    .footer .bottom .bottom-text{
        font-size: 23px;
    }
}


@media (min-width: 1920px){
    .footer .bottom{
        padding: 0px  15%;
    }

    .footer .bottom .bottom-text{
        font-size: 25px;
    }
}
