.App {
  text-align: center;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.section{
  width: 100%;
  height: 200px;
  background-color: #F4F4F4;
}

.section22{
  width: 100%;
  height: 200px;
  background-color: #979797;
}


