.socialButton{
    background-color: #AEB530;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px !important;
    min-width: 37px !important;
    margin: 0px;
    margin-left: 3%;
    border-radius: 12px;
    overflow: hidden;
    transform: perspective(1px) translateZ(0);
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.socialButton::after,
.socialButton::before{
    content: "";
    background: #008392;
    position: absolute;
    width: 0;
    height: 0;
    transform: translate(-31%,-27%);
    /*translate(-50%,-36%):;
    */transition: width .4s,height .4s;
    border-radius: 50%;
    z-index: -1;
    pointer-events: none;
    top:6px;
    left: 6px;
    -webkit-transition: width .4s,height .4s;
    -moz-transition: width .4s,height .4s;
    -ms-transition: width .4s,height .4s;
    -o-transition: width .4s,height .4s;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.socialButton:hover::after{
    width: 70px;
    height: 70px;
}
.socialButton a img{
    margin-top: 3px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
}

/* Querys */

@media  (min-width: 1921px) {
    .socialButton{
        height: 43px !important;
        min-width: 44px !important;
        margin-left: 4%;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
}
    .socialButton a img{
        width: 25px !important;
        min-width: 25px !important;
        height: 25px !important;
        min-height: 25px !important;
    }
}

@media  (min-width: 2100px) {
    .socialButton{
        height: 47px !important;
        min-width: 41px !important;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
}
}