 /* * Carousel styles */

 @media (min-device-width: 0px) and (max-device-width: 769px){
    .card-Service .image-carosuel-service {
        height: 30vw;
    }
    .list .category-secnd{
        width: 17vw;
        height: 17vw;
    }
    .title-content-cs{
        font-size: 3vw;
    }
    
 }

 @media (min-device-width: 425px) and (max-device-width: 600px){

    .title-content-cs{
        font-size: 
        3.1vw;
    }
 }
 @media (max-device-width: 425px){

    .card-Service .image-carosuel-service {
        height: 34vw;
    }
    .list .category-secnd{
        width: 19vw;
        height: 19vw;
    }

    .title-content-cs{
        font-size: 5.1vw;
    }
    
 }

@media (min-device-width: 769px) and (max-device-width: 1007px){
    
    .card-Service .image-carosuel-service {
        height: 24vw;
    }

    .title-content-cs{
        font-size: 2.8vw;
    }
    
}