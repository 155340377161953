/* *General Styles */

#Section4{
    background:  #F4F4F4;  
    padding: 0 34px 0 0px;
}

@media  (min-device-width: 1640px) {
    #Section4{
        padding: 0px 4%  0px 0%; 
    }
}

@media  (min-device-width: 1920px) {

    #Section4{
        padding: 0px 6% 0px 0%;
    }
}

/* *Section 4 image */

.S4-image{
    height: 100%;
    width: 50%;
    background: url(/public/resources/Fotos/cultureWadua.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.S4-image-img{
    width: 100%;
    height: 100%;
}

.line{
    background-color: #AEB530;
    width: 1px; 
    height: 80%;
}

/* *Section 4 content */
.S4-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    padding: 0% 11%;
    font-family: 'Quicksand';
}

.S4-content h2{
    color: #054F57;
    margin: 0px 0px 25px 0px;
}

.S4-content p{
    text-align: justify;
    margin: 0px 0px 25px 0px;
    color: #054F57;
}

.S4-content .S4-Btn{
    height: 13%;
    width: 50%;
    min-width: 154.4px;
    max-width: 188px;
    max-height: 184px;
}