#header{
    display: flex;
    padding: 0px 34px;
    align-items: center;
    height: 82px;
    background-color: #F4F4F4;
    z-index: 20;
    position: relative;
}
#header .homeLink{
    position: absolute;
    cursor: pointer;
    width: 16vw;
    height: 100%;
}

.WaduaColor,
#header .homeLink{
    margin-right: 30px;
}

#header .SM-container{
    display: flex;
    align-items: flex-start;
}
.back-header-movil{
    width: 100%;
    height: 190vh;
    background-color: #0a0a0a70;
    position: absolute;
    left: 0;
    z-index: -1;
}


/** Querys */

@media  (min-device-width: 1024px) {
    #header{
        min-width: 990px;
    }
}

@media  (min-device-width: 1640px) {
    #header{
        padding: 0px 4% ; 
    }
}

@media  (min-device-width: 1920px) {

    #header{
        padding: 0px 6%;
    }
}

