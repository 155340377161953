@media  (min-device-width: 0px) and (max-device-width: 768px) {
    #Section3{
        padding: 1%;
        flex-direction: column;
        height: auto;
        min-height: auto;
        padding: 10% 0% 4% 0%;
    }

    /* *Section 3 content */
    .S3-content{
        height: 50%;
        width: 100%;
        padding: 0% 5%;
    }

    .S3-content .S3-Btn{
        margin-bottom: 10%;
        height: 13vw;
        min-height: 46px;
        max-height: 46px;
    }

    /* *Section 3 image */

    .S3-image{
        height: 80%;
        width: 100%;
    }

    .S3-image .S3-carousel-title{
        color: #FFF;
        background-color: #48C0B4;
        width: 100%;
        height: 9vh;
        min-height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .S3-image .carousel-container{
        width: 100%;
        height: 60%;
    }

}

@media  (min-device-width: 769px) and (max-device-width: 1007px) {
    .S3-image .S3-carousel-title{
        margin-bottom: 50px;
    }
}