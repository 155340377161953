
label{
    display: block;
    width: 9vw;
    height: 9vw;
    min-width: 50px;
    margin: 10px;
    perspective: 1000px;
    transform-style: preserve-3d;
    cursor: pointer;
}

.img_sticker{
    position: absolute;
    align-self: center;
    justify-self: center;
}

.o-background{
    width: 100%;
    height: 100%;
}

.o-flip-card{
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition:all 0.5s ease-in-out;
    z-index: 1;
}

.o-flip-card .o-front, 
.o-flip-card .o-back{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.o-flip-card .o-back{
    transform: rotateY(180deg);
}

.o-flip-card .o-back  p{
    width: 100%;
    font-size: 1.1vw;
    position: absolute;
    text-align: center;
    color: white;
}

label:hover .o-flip-card{
    transform: rotateY(20deg);
}

input{
    display: none;
}

:checked + .o-flip-card{
    transform: rotateY(180deg);
}

label:hover :checked + .o-flip-card{
    transform: rotateY(175deg);
}

@media (max-device-width: 1023px) {

    label{
        width: 25vw;
        height: 25vw;
        min-width: 50px;
        margin: 5vw 7vw;
    }

    .o-flip-card .o-back  p{
        width: 100%;
        font-size: 3vw;
        position: absolute;
        text-align: center;
        color: white;
    }
}
