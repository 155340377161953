.carousel2{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.slides {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}


.list {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style-type: none;
    gap: 20px;
    margin: 0px;
}

.navButtons {
    width: 24px;
    height: 24px;
    margin: 0px 32px;
    cursor: pointer;
}

.dotsControls {
    display: block;
    text-align: center;
}

.list .img-team{
    width: 9vw;
    height: 9vw;
    border-radius: 50%;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.list .title-team
{
    font-family: 'Quicksand';
    color: #054F57;
    font-size: 1.2vw;
}

@media (min-device-width:0px) and (max-device-width: 768px){
    .list .img-team{
        width: 40vw;
        height: 40vw;     
    }

    .list {
        width: 100%; 
        margin-top: 0px;   
    }
    .list .title-team
    {
        font-size: 4vw;
    }
}

@media (min-device-width:532px) and (max-device-width: 768px){
    .list .img-team{
        width: 35vw;
        height: 35vw;     
    }
    .list .title-team
    {
        font-size: 3vw;
    }
}

@media (min-device-width:769px) and (max-device-width:1007px){
    .list .title-team
    {
        font-size: 2vw;
    }
    .list .img-team{
        width: 17vw;
        height: 17vw;
    }
    
}