#Section3{
    background-color:#FFF;    
    padding: 0 34px;
}

@media  (min-device-width: 1640px) {
    #Section3{
        padding: 0px 4% ; 
    }
}

@media  (min-device-width: 1920px) {

    #Section3{
        padding: 0px 6%;
    }
}

/* *Section 3 content */
.S3-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    padding: 0% 11%;
    font-family: 'Quicksand';
}

.S3-content h2{
    color: #AEB530;
    margin: 0px 0px 25px 0px;
}

.S3-content p{
    text-align: justify;
    margin: 0px 0px 25px 0px;
    color: #054F57;
}

.S3-content .S3-Btn{
    height: 13%;
    width: 50%;
    max-height: 184px;
    min-width: 154.4px;
    max-width: 188px;
    position: relative;
}

/* *Section 3 image */

.S3-image{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 50%;
}

.S3-image .S3-carousel-title{
    font-family: 'Quicksand';
    color: #008392;
    margin: 0px;
}

.S3-image .carousel-container{
    width: 100%;
    height: 60%;
}
