.our-services{
    flex-direction: column;
    background-color: #F4F4F4;
    min-width: 320px;
}

.our-services-title{
    font-family: 'Quicksand';
    width: 100%;
    text-align: start;
    padding-left: 10%;
    color: #AEB530;
    font-weight: bold;
    background-color: #F4F4F4;
}

.os-carousel-container{
    background-color: #FFF;
}
.category-secnd{
    box-shadow: none;
}

.card-Service{
    background-color: #FFF;
}

.card-Service .image-carosuel-service{
    background-color: #054F57;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17vw;
    overflow: hidden;
    position: relative;
}

.card-Service:first-child .image-carosuel-service{
    border-bottom-left-radius: 45px;
}

.card-Service:last-child .image-carosuel-service::before{
    content: '';
    background: transparent;
    width: 150%;
    height: 200%;
    border-radius: 50%;
    position: absolute;
    right: -5%;
    bottom: -16%;
    box-shadow: 143px 5px 0px 66px #aeb530;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.content-carousel-service{
    height: auto;
}

.content-carousel-service{
    padding: 0% 15%;
    background-color: #FFF;
}

.title-content-cs{
    width: 100%;
    text-align: start;
    font-family: 'Quicksand';
    font-size: 2vw;
    font-weight: bold;
    color: #48C0B4;
}

.span-content-cs{
    display: flex;
    width: 100%;
    text-align: start;
    color: #054F57;
    font-family: 'Quicksand';
    font-size: 13px;
    font-weight: bold;
}