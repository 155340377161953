.Section2ABUC-desc{
    background-color: #F4F4F4;
    position: relative;
    overflow: hidden;
    padding: 0 0 0 0px;
}
.Section2ABUC-desc .upper-slice{
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #008392;
    left: -10%;
    top: 0px;
    width: 120%;
    height: 20%;
    border-radius: 0% 0% 0% 100%;
    z-index: 1;
    -webkit-border-radius: 0% 0% 0% 100%;
    -moz-border-radius: 0% 0% 0% 100%;
    -ms-border-radius: 0% 0% 0% 100%;
    -o-border-radius: 0% 0% 0% 100%;
} 

.Section2ABUC-desc .upper-slice::before{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 65%;
    height: 700%;
    top: -460%;
    left: -12%;
    border-radius: 50%;
    background:  #aeb530;
    background-position: 118% -124px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media  (min-device-width: 1640px) {
    .about-us-C-desc{
        padding: 0px 4% ; 
    }
}

@media  (min-device-width: 1920px) {

    .about-us-C-desc{
        padding: 0px 6%;
    }
}

.Section2ABUC-img{
    width: 50%;
    height: 100%;
    position: relative;
    background-image: url('/public/resources/Fondos/panchoTexture.svg');
    background-repeat: repeat;
    background-size: 100% 100%;
}

@media(min-device-width:1440px){
    .Section2ABUC-img{
        background-size: 66% 100%;
    }
}

.Section2ABUC-img::before{
    content: "";
    background: #FFF ;
    position: absolute;
    bottom: -17%;
    right: -38px;
    width: 58%;
    height: 75%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.Section2ABUC-img::after{
    content: "";
    background:  url('/public/resources/Vectores/Soluciones.svg');
    -webkit-filter: blur(5px);
            filter: blur(5px);
    position: absolute;
    width: 58%;
    height: 75%;
    bottom: -17%;
    right: -38px;
    -webkit-filter: brightness(0) saturate(100%) invert(71%) sepia(50%) saturate(559%) hue-rotate(20deg) brightness(87%) contrast(93%);
            filter: brightness(0) saturate(100%) invert(71%) sepia(50%) saturate(559%) hue-rotate(20deg) brightness(87%) contrast(93%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    min-width: 223px;
    min-height: 223px;
}

@media(min-device-width: 1024px){
    .Section2ABUC-img::before,
    .Section2ABUC-img::after{
        width: 30vw;
        height: 30vw;
        max-width: 323px;
        max-height: 323px;
    }
}

@media(min-device-width:1440px){
    .Section2ABUC-img::before,
    .Section2ABUC-img::after{
        width: 55%;
        height: 84%;
    }
}
@media(min-device-width:1920px){
    .Section2ABUC-img::before,
    .Section2ABUC-img::after{
        width: 44%;
        height: 87%;
        max-width: 523px;
        max-height: 523px;
    }
}

.Section2ABUC-content{
    width: 50%;
    height: 100%;
    padding: 0% 11%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Section2ABUC-content .Section2ABUC-content-title{
    color: #008392;
}

